/* FAQs.css */

.faq-container {
    max-width: 800px;
    margin: 0 auto;
  }

  .faqs-container{
    background-color: #f4f4f4;
  }
  
  .faq-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 20px;
    transition: transform 0.3s ease-in-out;
  }
  
  .faq-item:hover {
    transform: scale(1.02);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .faqsection h2{
    margin-bottom: 20px;
  }
  p {
    color: #666;
    font-size: 1rem;
  }
  
  .stickysection{
    position: sticky;
    top: 50px;
    background-color: #fff;
    padding-top: 20px;
    z-index: 999;
  }
  
  