body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto', sans-serif;
}

p{
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
h1,h2,h3,h4,h5,h6{
  font-family:'Lato', sans-serif !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
}
h6{
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
}

.text-white{
  color: #fff;
  
}
hr {
  color: #D9D9D9;
  margin: 0 !important;
  opacity: 1;
}

/* button */
/* 
.btn, .button.action {
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  background-color: #222;
  border-color: #739bd0 !important;
  padding: 17px 60px;
  margin: 0 auto;
  box-shadow: 0 5px 15px rgba(0,0,0,0.20);
  border-radius: 50px !important;
}

.btn span, .button.action span {
  position: relative; 
  z-index: 1;
}

.btn:after, .button.action:after{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 490%;
  width: 140%;
  background: #739bd0;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
  transform: translateX(-98%) translateY(-25%) rotate(45deg);
}
.btn:hover, .button.action:hover{
  background-color: transparent !important;
}
.btn:hover:after {
  z-index: -99;
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}
.button.action:hover:after {
  z-index: -99;
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}
.button.action:hover{
  z-index: 99;
  color: #fff;
} */

.box-price{
  font-weight: 600;
}
.offertext{
  text-align: end;
  padding-top: 10px;
  font-weight: bold;
  margin-bottom: 0;
}
.activatetext {
  text-align: start;
  font-size: 12px;
  color: #1e4851;
  font-weight: 600;
  margin: 0;
  margin-top: 10px;
}
.react-multi-carousel-item{
  text-align: center;
}
textarea.form-control {
  border-color: #000;
}
.modal{
  z-index: 999999 !important;
}
.react-tel-input .country-list .country-name{
  color: #000;
}
.react-tel-input .form-control{
  border:0px !important;
  border-bottom: 1px solid #ccc !important;
  width: 100% !important;
  padding-left: 40px !important;
}
.statustext{
  text-align: end;
  font-weight: 600;
  text-transform: capitalize;
  display:none;
}
.reserved-text{
   color: red;
  text-align: end;
  font-weight: 600;
  text-transform: capitalize;
}
.react-multiple-carousel__arrow{
  z-index: 999 !important;
}
.columnrow50{
  /* width: 50%; */
}
div:where(.swal2-container) h2:where(.swal2-title){
  font-size: 24px !important;
}
div:where(.swal2-container) .swal2-html-container{
  font-size: 16px !important;
}


.react-multiple-carousel__arrow--left, .react-multiple-carousel__arrow--right{
  display: none;
}
/* Pagination */
.active>.page-link, .page-link.active{
  background-color: #1e4851 !important;
}
.active>.page-link, .page-link.active .page-link{
  color: #fff !important;
}
.page-link{
  color: #1e4851 !important ;
}
ul.pagination {
  margin-top: 20px;
  justify-content: end;
}