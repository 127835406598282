/* .container {
    padding: 20px;
  } */
.oursalessection{
    background: #cbdbcd;
    padding: 20px;
}  
  .box {
    border: 1px solid #ddd;
    border-radius: 10px; 
    padding: 20px;
    margin: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  .box-patterns{
    color: #000;
font-family: Roboto;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 12px; /* 120% */
  }
  .box-discount{
    color: #1e4851;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 120% */
    margin: 0;
    margin-left: 5px;
    margin-bottom: 20px;
  }
  .box-number {
    font-size: 24px;
    font-weight: bold;
  }
  .location-button{
    border-radius: 8px;
    margin-top: 10px;
    border: 0px solid  #ddd;
    background-color: transparent;
    padding: 0;
  }

  .buybutton {
    background-color:#1e4851; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 5px; 
    cursor: pointer;
    font-size: 16px;
    /* margin-top: 10px; */
    width: 85%;
        display: flex;
    margin-left: auto;
    justify-content: center;
    align-items: center;
  }
  @media (min-width:768px) and (max-width: 1023px) {
    .buybutton{
      width:100%
    }
  }
  @media (max-width:767px) {
  .buybutton{
    margin-top: 20px;
    width:70%
  }
}